import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  Bar,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { useEffect, useState } from "react";
import universalStyle from "../universalStyle";
import { Button } from "react-bootstrap";

function StockModalInfo(props) {
  const [sum, setSum] = useState(0);

  useEffect(() => {
    console.log(props.details);
    // Calculate the sum of 'div' values in the data array
    let totalSum = 0;
    for (const item of props.details.divDate) {
      totalSum += item.dividend;
    }
    setSum(totalSum);
  }, [props.details]); // Run this effect whenever the 'data' array changes

  return (
    <>
      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
          <Modal.Title>{props.details.symbol}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <>
            <div
              style={{
                display: "flex",
                marginBottom: 10,
                color: universalStyle.colours.tertiary,
                fontSize: 13,
                justifyContent: "space-between",
              }}
            >
              <div>
                <h5 style={{ color: universalStyle.colours.secondary }}>
                  Fundamentals:
                </h5>
                <span style={{ fontWeight: "bold", color: "black" }}>
                  Price:
                </span>{" "}
                {props.details.price}
                <span
                  style={{ fontWeight: "bold", color: "black", marginLeft: 20 }}
                >
                  Weight:
                </span>{" "}
                {(
                  ((props.details.price * props.details.noOfShares) /
                    props.value) *
                  100
                ).toFixed(2)}
                %
                <span
                  style={{ fontWeight: "bold", color: "black", marginLeft: 20 }}
                >
                  Volatility:
                </span>{" "}
                {props.details.volatility}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: 10,
                color: universalStyle.colours.tertiary,
                fontSize: 13,
              }}
            >
              <div>
                <h5 style={{ color: universalStyle.colours.secondary }}>
                  Dividend Analysis:
                </h5>
                <span style={{ fontWeight: "bold", color: "black" }}>
                  Payout Ratio TTM:
                </span>{" "}
                {props.details.payout}%<br></br>
                <span style={{ fontWeight: "bold", color: "black" }}>
                  Dividend Cover Ratio TTM:
                </span>{" "}
                {props.details.cover}
                <br></br>
                <span style={{ fontWeight: "bold", color: "black" }}>
                  Dividend Per Share TTM:
                </span>{" "}
                {props.details.dps}
              </div>
              <div style={{ marginTop: 27, marginLeft: 15 }}>
                <span style={{ fontWeight: "bold", color: "black" }}>
                  cashFlowCoverageRatiosTTM:
                </span>{" "}
                {props.details.cashFlowCoverageRatiosTTM.toFixed(2)}
                <br></br>
                <span style={{ fontWeight: "bold", color: "black" }}>
                  Dividend Yield TTM:
                </span>{" "}
                {props.details.yield}%<br></br>
                <span style={{ fontWeight: "bold", color: "black" }}>
                  Dividend Yield on Cost:
                </span>{" "}
                {((sum / props.details.cost) * 100).toFixed(2)}%
              </div>
            </div>
            <h5>Investor Ratings:</h5>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: universalStyle.colours.secondary,
                fontSize: 13,
                fontWeight: "bold",
                color: "black",
              }}
            >
              <span>Strong Buy</span>
              <span>Buy</span>
              <span>Hold</span>
              <span>Sell</span>
              <span>Strong Sell</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between", // evenly distribute children
                alignItems: "center", // align items vertically in the center
                padding: "0 40px", // optional: add padding to the container for better spacing
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  color: universalStyle.colours.tertiary,
                }}
              >
                {JSON.stringify(props.details.rec?.analystRatingsStrongBuy)}
              </div>

              <div
                style={{
                  transform: "translateX(2px)",
                  textAlign: "center",
                  color: universalStyle.colours.tertiary,
                  marginLeft: 8,
                }}
              >
                {JSON.stringify(props.details.rec?.analystRatingsbuy)}
              </div>

              <div
                style={{
                  transform: "translateX(-9px)",

                  color: universalStyle.colours.tertiary,
                }}
              >
                {JSON.stringify(props.details.rec?.analystRatingsHold)}
              </div>

              <div
                style={{
                  transform: "translateX(-12px)",

                  textAlign: "center",
                  color: universalStyle.colours.tertiary,
                }}
              >
                {JSON.stringify(props.details.rec?.analystRatingsSell)}
              </div>

              <div
                style={{
                  transform: "translateX(10px)",

                  textAlign: "center",
                  color: universalStyle.colours.tertiary,
                }}
              >
                {JSON.stringify(props.details.rec?.analystRatingsStrongSell)}
              </div>
            </div>
            <div>
              <h5
                style={{
                  color: universalStyle.colours.secondary,
                  paddingTop: 10,
                }}
              ></h5>

              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th
                      colspan="4"
                      style={{
                        textAlign: "center",
                        padding: "10px",
                        backgroundColor: "#eee",
                      }}
                    >
                      {" "}
                      Financials ( £ 000,000):
                    </th>
                  </tr>
                  <tr>
                    <th style={{ padding: "10px", backgroundColor: "#f4f4f4" }}>
                      Year
                    </th>
                    <th style={{ padding: "10px", backgroundColor: "#f4f4f4" }}>
                      Revenue
                    </th>
                    <th style={{ padding: "10px", backgroundColor: "#f4f4f4" }}>
                      Costs
                    </th>
                    <th style={{ padding: "10px", backgroundColor: "#f4f4f4" }}>
                      Profit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {props.details?.financials[0][0]}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {props.details?.financials[0][1]}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {props.details?.financials[0][2]}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {(
                        props.details?.financials[0][1] -
                        props.details?.financials[0][2]
                      ).toFixed(2)}
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f9f9f9" }}>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {props.details?.financials[1][0]}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {props.details?.financials[1][1]}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {props.details?.financials[1][2]}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {(
                        props.details?.financials[1][1] -
                        props.details?.financials[1][2]
                      ).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {props.details?.financials[2][0]}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {props.details?.financials[2][1]}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {props.details?.financials[2][2]}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {(
                        props.details?.financials[2][1] -
                        props.details?.financials[2][2]
                      ).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h5
              style={{ color: universalStyle.colours.secondary, marginTop: 10 }}
            >
              Graphs:
            </h5>
            Dividends received in the past 5 years
            <LineChart
              width={450}
              height={560}
              data={props.details.previousDiv}
              margin={{
                top: 5,
                right: 30,
                left: -20,
              }}
            >
              <Tooltip
                label={"Number"}
                labelFormatter={(unixTime) => [
                  new Date(unixTime * 1000).toLocaleDateString("en-GB", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour12: false,
                  }),
                ]}
              />
              <YAxis />
              <XAxis
                tickMargin={49}
                height={140}
                angle={45}
                dataKey="date"
                scale={"time"}
                hide={false}
                type="number"
                domain={["auto", "auto"]}
                tickFormatter={(unixTime) =>
                  new Date(unixTime * 1000).toLocaleDateString("en-GB", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour12: false,
                  })
                }
              />
              <Line dataKey="amount" fill="#E8DBCD" stroke="#E8DBCD" />
            </LineChart>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              props.handleShowEdit(
                props.details._id,
                props.details.cost,
                props.details.symbol,
                props.details.noOfShares,
                props.details.divDate,
                props.details.sold,
                props.details.soldData,
                props.details.buyData
              );
            }}
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              props.deleteStock(
                props.details._id,
                props.details.cost,
                props.details.symbol,
                props.details.noOfShares,
                props.details.sold,
                props.details.soldData,
                props.details.buyData
              );
            }}
          >
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default StockModalInfo;
