import { Form, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import VerificationInput from "react-verification-input";
import ForgotPassword from "./forgotPassword";
import { useNavigate } from "react-router-dom";
import DataContext from "../DataContext";

export default function Register(props) {
  const [logreg, setLogReg] = useState("Log In");
  const { setData } = useContext(DataContext);

  const [username, setUsername] = useState({ username: "" });
  const [password, setPassword] = useState({ password: "" });
  const [message, setMessage] = useState(null);
  const [verify, setVerify] = useState(false);
  const [code, setCode] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  let navigate = useNavigate();

  const close = () => {
    setShowPassword(false);
  };

  function register() {
    axios
      .post(
        process.env.REACT_APP_API_VERIFY,
        { username: username, password: password },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        setMessage(res.data.message);
        setCode(res.data.code);
        setVerify(true);
      })
      .catch((err) => {
        err.data.message ? setMessage(err.data.message) : console.log(err.data);
      });
  }

  function verification(e) {
    if (e == code) {
      setMessage("SUCCESS");
      axios
        .post(
          process.env.REACT_APP_API_REGISTER,
          { username: username, password: password },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((res) => {
          setMessage(res.data.message);

          setVerify(true);
        })
        .catch((err) => {
          err.data.message
            ? setMessage(err.data.message)
            : console.log(err.data);
        });
    } else {
      setMessage("Invalid verification code");
    }
  }

  function logIn() {
    setSpinner(true);
    axios
      .post(
        process.env.REACT_APP_API_LOGIN,
        { username: username, password: password },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        setSpinner(false);
        setMessage(res.data.message);

        if (res.data.message == "Login Successful") {
          setData({ email: res.data.email, token: res.data.token });

          props.setShow(false);
          navigate("/portfolio");
          // window.location.reload();
        }
      })
      .catch((err) => {
        setSpinner(false);
        setMessage("Login Failed");
      });
  }

  function passwordReset() {
    setShowPassword(true);
    props.setShow(false);
  }

  return (
    <>
      <ForgotPassword
        showPassword={showPassword}
        setShowPassword={setShowPassword}
        close={close}
      />

      <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
          <Modal.Title>{logreg}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            {/* email */}
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>

            {/* password */}
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            {logreg == "Log In" ? (
              <div>
                <p
                  style={{ cursor: "pointer", marginBottom: 10 }}
                  onClick={() => setLogReg("Register")}
                >
                  Sign Up?
                </p>
                <Button
                  disabled={spinner}
                  style={{ marginRight: 40 }}
                  onClick={() => logIn()}
                  variant="primary"
                  type="submit"
                >
                  Submit{" "}
                  {spinner ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}{" "}
                </Button>
                <p
                  onClick={() => passwordReset()}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  Forgot Password?
                </p>
                <p>{message}</p>
              </div>
            ) : (
              <div>
                <p
                  style={{ cursor: "pointer", marginBottom: 10 }}
                  onClick={() => setLogReg("Log In")}
                >
                  Log in?
                </p>
                <p>{message}</p>
                {verify ? (
                  <div style={{ marginTop: 10 }}>
                    <VerificationInput
                      length={4}
                      onComplete={(e) => verification(e)}
                    />
                  </div>
                ) : null}

                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    register();
                  }}
                  variant="primary"
                  type="submit"
                >
                  Submit{" "}
                </Button>
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
