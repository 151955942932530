import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Icon from "react-crud-icons";
import axios from "axios";

const StockSearch = (props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedStocks, setSelectedStocks] = useState([]);
  const [focus, setFocus] = useState(false);
  const [wishListStock, setWishListStock] = useState({});
  const [loading, isLoading] = useState(false);

  useEffect(() => {
    setSelectedStocks(props.configData.wishlist);
  }, [props.configData.wishlist]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    const data = [];
    isLoading(true);
    await axios
      .request({
        method: "GET",
        url: `https://financialmodelingprep.com/api/v3/search-ticker?query=${searchQuery}&limit=10&exchange=LSE&apikey=6NnXQiKotJ8a06rfMG4LRgnSbRWmmnAc`,
      })
      .then((res) => {
        isLoading(false);
        const result = res.data;
        console.log(result);
        setFocus(true);

        result.forEach((r) => {
          data.push({
            value: r.symbol,
            text: r.name,
          });
        });
        data.length == 0 ? setSearchResults(["Empty"]) : setSearchResults(data);
      })
      .catch((err) => {
        console.error("Error fetching search results:", err);
        setSearchResults([{ value: "", text: "No results found" }]);
      });
  };
  // Simulated API call to fetch search results based on search query

  const handleResultClick = () => {
    setFocus(false);
    setSelectedStocks([...selectedStocks, wishListStock]);
    props.updateConfig("wishlist", [...selectedStocks, wishListStock]);
  };

  const removeAlert = (i) => {
    const newItems = [...selectedStocks];
    // Remove the item at the specified index
    newItems.splice(i, 1);
    // Update the state
    setSelectedStocks(newItems);
    props.updateConfig("wishlist", newItems);
  };

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
      }}
    >
      <h1
        style={{
          fontFamily: "Helvetica Neue, Arial, sans-serif",
          fontSize: "1.5em",
          fontWeight: 700,
          color: "#333",
          margin: "0.5em 0",
          lineHeight: 1.2,
          letterSpacing: "0.05em",
          textAlign: "center",
          textTransform: "uppercase",
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
          borderBottom: "2px solid #e2e2e2",
          paddingBottom: "0.3em",
        }}
      >
        Wishlist
      </h1>
      <form
        onSubmit={(e) => handleSearch(e)}
        style={{ marginBottom: "20px", maxWidth: "500px", margin: "auto" }}
      >
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search for a stock..."
          style={{
            padding: "8px",
            marginRight: "10px",
            width: "70%",
            borderColor: "ActiveBorder",
          }}
          onFocus={() => setFocus(true)}
          onBlur={() => {
            setTimeout(() => {
              setFocus(false);
            }, 250);
          }}
        />
        <Button type="submit">Search</Button>
      </form>
      <div>
        <ul
          style={{
            listStyle: "none",
            position: "absolute",
            padding: 0, // Remove default padding
          }}
        >
          {focus
            ? searchResults.map((result, index) => (
                <li
                  onClick={(e) => handleResultClick(e)}
                  style={{
                    padding: "8px",
                    cursor: "pointer",
                    borderStyle: "solid",
                    transition: "background-color 0.3s ease",
                    backgroundColor: "white",
                    marginBottom: "1px", // Add margin between list items
                  }}
                  key={index}
                  onMouseEnter={(e) => {
                    setWishListStock(result.text);
                    e.target.style.backgroundColor = "lightgray";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "white";
                  }}
                >
                  {result.text}
                </li>
              ))
            : null}
        </ul>

        {selectedStocks.map((stock, index) => (
          <div>
            <div
              style={{
                color: "black",
                background: "rgba(255, 255, 255, 0.7)",
                borderRadius: 10,
                margin: 20,
                marginRight: 100,
                marginLeft: 100,
              }}
            >
              {" "}
              {stock}{" "}
              <Icon
                name="remove"
                tooltip="remove"
                theme="light"
                size="small"
                onClick={() => removeAlert(index)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StockSearch;
