import React, { useState } from "react";
import universalStyle from "../universalStyle";
import Icon from "react-crud-icons";

const StockInfoCard = ({ stockData }) => {
  const {
    imageUrl,
    cost,
    price,
    noOfShares,
    title,
    divDate,
    _id,
    handleShow,
    n,
    open,
  } = stockData;
  const [isHovered, setIsHovered] = useState(false);

  function sumArray(arr) {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
      sum += arr[i].dividend;
    }
    return sum;
  }
  function simplifiedStockPerformance(
    totalCost,
    totalShares,
    originalDate,
    currentPrice,
    dividendsReceived
  ) {
    // Calculate the total current value of shares
    const totalValue = totalShares * currentPrice;

    // Calculate total return (capital gain + dividends)
    const totalReturn = totalValue - totalCost + dividendsReceived;
    const totalReturnPercentage = (totalReturn / totalCost) * 100;

    // Calculate months held
    const startDate = new Date(originalDate);
    const currentDate = new Date();
    const monthsHeld =
      (currentDate.getFullYear() - startDate.getFullYear()) * 12 +
      currentDate.getMonth() -
      startDate.getMonth();

    // Calculate return per month
    const totalReturnRatio = totalReturnPercentage / monthsHeld;

    return totalReturnPercentage;
  }

  function calculateCompositeIndex(
    totalReturnRatio,
    volatility,
    dividendRatio,
    payout
  ) {
    const weights = {
      totalReturnRatio: 0.3,
      volatility: 0.2,
      dividendRatio: 0.3,
      payout: 0.2,
    };
    // Normalize or scale factors if necessary
    const normalizedVolatility = 1 - volatility; // Assuming lower volatility is better, for example

    // Calculate composite index
    const compositeIndex =
      (totalReturnRatio * weights.totalReturnRatio +
        normalizedVolatility * weights.volatility +
        dividendRatio * weights.dividendRatio +
        payout * weights.payout) /
      Object.values(weights).reduce((a, b) => a + b);

    let starRating;
    if (compositeIndex < 0) {
      starRating = 1;
    } else if (compositeIndex > 3) {
      starRating = 5;
    } else if (compositeIndex > 2) {
      starRating = 4;
    } else if (compositeIndex > 1) {
      starRating = 3;
    } else if (compositeIndex > 0.5) {
      starRating = 2;
    } else {
      starRating = 1;
    }

    return starRating;
  }

  return (
    <div
      onClick={() => open(n)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={
        isHovered
          ? {
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              display: "flex",
              border: "1px solid #ccc",
              width: "20%",
              overflow: "hidden",
              margin: "16px",
              height: 100,
              borderRadius: 15,
              boxShadow: "10px 10px 10px 10px",
              minWidth: 660,
              borderColor: "silver",
              boxShadow: "0 0 10px rgba(0, 0, 255, 0.5)",
              cursor: "pointer",
            }
          : {
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              display: "flex",
              border: "1px solid #ccc",
              width: "20%",
              overflow: "hidden",
              margin: "16px",
              height: 100,
              borderRadius: 15,
              boxShadow: "5px 5px 5px 5px",
              minWidth: 660,
            }
      }
    >
      <div style={styles.imageContainer}>
        <img src={imageUrl} alt="Stock" style={styles.image} />
      </div>
      <div style={styles.infoContainer}>
        <h5
          style={{
            position: "absolute",
            zIndex: 0,
            transform: "translate(-25%, -120%)",
          }}
        >
          {title}{" "}
        </h5>

        <div style={styles.infoItem}>
          <text>
            Cost:
            <span style={{ color: universalStyle.colours.secondary }}>
              {" "}
              {new Intl.NumberFormat("en-UK", {
                style: "currency",
                currency: "GBP",
              }).format(
                n.buyData.reduce((total, item) => total + item.cost, 0)
              )}
            </span>
          </text>
        </div>
        <div style={styles.infoItem}>
          <text>
            {" "}
            Value:
            <span style={{ color: universalStyle.colours.secondary }}>
              {" "}
              {new Intl.NumberFormat("en-UK", {
                style: "currency",
                currency: "GBP",
              }).format(
                price *
                  n.buyData.reduce(
                    (total, item) => total + item.noOfSharesBought,
                    0
                  )
              )}
            </span>
          </text>
        </div>
      </div>
      <div style={styles.extraInfoContainer}>
        <div style={styles.infoItem}>
          <text>
            Gain:
            <span
              style={
                price * noOfShares - cost > 0
                  ? { color: "green" }
                  : { color: "red" }
              }
            >
              {" "}
              {new Intl.NumberFormat("en-UK", {
                style: "currency",
                currency: "GBP",
              }).format(price * noOfShares - cost)}
            </span>
          </text>
        </div>
        <div style={styles.infoItem}>
          <text>
            Dividends:
            <span style={{ color: universalStyle.colours.secondary }}>
              {" "}
              {"£" + sumArray(divDate)}
            </span>
          </text>
        </div>
      </div>
      <div style={styles.extraInfoContainer}>
        <div style={styles.infoItem}>
          <text>
            Return Ratio:
            <span style={{ color: universalStyle.colours.secondary }}>
              {" "}
              {simplifiedStockPerformance(
                cost,
                noOfShares,
                "10/01/2024",
                price,
                sumArray(divDate)
              ).toFixed(2) + "%"}
            </span>
          </text>
        </div>
        <div style={styles.rating}>
          <text>
            DH Rating:
            <span style={{ color: universalStyle.colours.secondary }}>
              {" "}
              {calculateCompositeIndex(
                n.yield,
                n.volatility,
                n.cover,
                n.payout
              )}
              <Icon name="favorite" theme="light" size="medium" />
            </span>
          </text>
        </div>
      </div>
    </div>
  );
};

const styles = {
  imageContainer: {
    flex: "0 0 auto",
  },

  image: {
    width: "100px",
    height: "100px",
    objectFit: "cover",
  },
  infoContainer: {
    flex: "1 1 auto",
    padding: "16px",
    paddingTop: "25px",
  },
  extraInfoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: "16px",
    paddingTop: "25px",
  },
  infoItem: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "8px",
  },
  rating: {
    transform: "translate(-25px,-10px)",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "8px",
  },
  editButtons: {
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default StockInfoCard;
