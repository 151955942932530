import { useEffect, useState, useContext } from "react";
import NumberInput from "../components/numberInput";
import PortRatio from "../components/portRatio";
import Wishlist from "../components/Wishlist";
import Risk from "../components/risk";
import axios from "axios";
import DataContext from "../DataContext";

function Target(props) {
  const { data } = useContext(DataContext);
  const [barData, setBarData] = useState([{ x: "x" }]);

  const [configData, setConfigData] = useState({
    divGoal: 10,
    diversification: [],
    wishlist: [],
    alerts: [],
  });

  function checkPriceAlert() {
    configData.alerts.map((alert) => {
      const compare = 1000;
      if (alert.alarm == "Price") {
        //get curremt price of all stocks in alarm
        //currentPrice = api to get price;
        if (compare <= alert.num) {
          updateConfig("alerts", [
            ...alert,
            {
              stock: alert.stock,
              alarm: alert.alarm,
              num: alert.num,
              isTrue: true,
            },
          ]);
        }
      } else if (alert.alarm == "Yeild") {
        //get curremt price of all stocks in alarm
        //currentPrice = api to get yeild;
        if (compare <= alert.num) {
          updateConfig("alerts", [
            ...alert,
            {
              stock: alert.stock,
              alarm: alert.alarm,
              num: alert.num,
              isTrue: true,
            },
          ]);
        }
      }
    });
  }

  useEffect(() => {
    checkPriceAlert();
    axios
      .post(
        process.env.REACT_APP_API_GETCONFIG,
        { username: data.email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
        }
      )
      .then((res) => {
        setConfigData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const updateConfig = (x, y) => {
    axios
      .post(
        process.env.REACT_APP_API_UPDATECONFIG,
        [data.email, { key: x, value: y }],
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_GRAPHS2, {
        params: {
          user: data.email,
          token: data.token,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
      })
      .then((res) => {
        setBarData(res.data[0]);
      });

    return;
  }, [props.data]);

  return (
    <div
      style={{
        ...styles.parent,
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            ...styles.whiteBox,
            flex: 1, // Add this line
          }}
        >
          <NumberInput
            data={props.data}
            div={props.div}
            updateConfig={updateConfig}
            configData={configData}
            barData={barData}
          />
        </div>
        <div
          style={{
            ...styles.whiteBox,
            flex: 1, // Add this line
          }}
        >
          <PortRatio updateConfig={updateConfig} configData={configData} />
        </div>
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <div
          style={{
            ...styles.whiteBox,
            flex: 1, // Add this line
            backgroundColor: "rgba(78, 122, 140, 0.9)",
          }}
        >
          <Risk
            updateConfig={updateConfig}
            configData={configData}
            data={props.data}
          />
        </div>
        <div
          style={{
            ...styles.whiteBox,
            flex: 1, // Add this line
            backgroundColor: "rgba(78, 122, 140, 0.9)",
          }}
        >
          <Wishlist updateConfig={updateConfig} configData={configData} />
        </div>
      </div>
    </div>
  );
}

const styles = {
  parent: {},
  whiteBox: {
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 15,
    boxShadow: "10px 10px 10px 10px",
    marginTop: 20,
  },
};

export default Target;
//
